

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body{
  font-family: "Noto Sans TC","Microsoft JhengHei",Arial,sans-serif;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 15px;
  min-width: 1380px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.container {
  width: 100%;
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
#menu .container{

  width: 100%;
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
#menu>ul {
  display: flex;
  list-style: none;
  margin: 0 auto;
}
#menu>ul>li>a {
  line-height: 60px;
  padding: 0 20px;
  white-space: nowrap;
  font-weight: bold;
}
#menu>ul>li>div {
  display: none;
  position: absolute;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
  z-index: 10;
}
#menu >ul>li>div ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  flex-wrap: wrap;
}
#menu >ul>li>div ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  flex-wrap: wrap;
}
 #menu>ul>li>a {
  line-height: 40px;
  color: rgba(0,0,0,0.85);
    text-decoration: none;
}
#menu>ul>li:hover>a {
  color: #01d8da;
}
#menu > ul > li:hover > div {
  display: block;
  }
  #menu >ul>li>div ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    flex-wrap: wrap;
}
.mega-menu > .container>ul li.thirdmenu {
  font-size: 14px;
}
#topbar {
  background-color: #01d8da;
  font-size: 13px;
  height: 30px;
}
#topbar .container {
  display: flex;
  height: 100%;
}
#topbar .top-bar-left {
  width: 50%;
  line-height: 30px;
  float:left;
}
#topbar .top-bar-left a {
  color: #fff;
  margin-right: 10px;
}
#topbar .top-bar-right {
  width: 50%;
  text-align: right;
  line-height: 30px;
  float: right;
}
#topbar .top-bar-right button{
  background: #fff;
  padding: 2px 10px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: none;
  border: none;
}
#cart .cart-icon {
  width: 25px;
  height: 25px;
  background-image: url(https://bip-react.trillionhk.com/image/cart-icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-right: 30px;
}
#header #cart .heading #cart-total {
  position: absolute;
  background: #01d8da;
  width: 18px;
  height: 18px;
  line-height: 18px;
  top: -30px;
  left: 18px;
  border-radius: 18px;
  text-align: center;
  color: #fff;
  font-size: 12px;
}
.cart-total{
  position: relative;
}
#topbar .top-bar-right a {
  color: #fff;
  margin-left: 10px;
}
#topbar .container > div {
 width:100%;
}
#header>.container>.left {
  width: 300px;
}
#header .center {
  width: calc(100% - 600px);
}
#header .container{
  display: flex;
}
#header {
  padding: 30px 0 20px;
}
#header #search {
  width: 100%;
  height: 40px;
  max-width: 400px;
  margin-left: 40px;
  background: rgb(245, 245, 245);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
#header #search input {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
  padding: 0px 56px 0 16px;
  background: transparent;
  line-height: 40px;
}
.ui-helper-hidden-accessible {
  display: none;
}
#header #search .button-search {
  text-align: center;
  width: 40px;
  font-size: 20px;
  content: " ";
 background: url(https://upload.wikimedia.org/wikipedia/commons/0/0b/Search_Icon.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  display: block;
  right: 0;
  top: 0;
  position: absolute;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}
.pricepromise-1 p {
  font-size: 18px;
  margin: 20px auto;
  line-height: 2;
}
.pricepromise-1 {
  background: url(https://bip-react.trillionhk.com/image/data/pages/price_promise/bg-price-promise.jpg) !important;
  background-attachment: fixed;
  background-position: top;
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10% 20px;
  min-height: 600px;
  background-attachment: fixed !important;
  background-position: top !important;
}
header {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
  background: #fff;
}
header {
  position: fixed;
  width: 100%;
  top: -200px;
  margin-top: 200px;
  left: 0;
  transition: all 300ms ease 0s;
}
.mega-menu>.container>ul>li.submenu>a {
  display: block;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}
.mega-menu>.container>ul>li.submenu>img {
  float: right;
}
#menu >ul>li>div ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  flex-wrap: wrap;
}
.dropdown li a {
  padding: 10px;
  display: block;
}
.mega-menu>.container>ul>li.submenu {

  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
#menu ul li a{
  color: #342e2e;
  text-decoration: none;
}
#menu >ul>li>div li a:hover {
  color: #01d8da;
}
.mega-menu > .container>ul li.thirdmenu {
  font-size: 14px;
}
footer {
  background-color: #292c32;
}
footer b, footer p, footer li, footer div, footer a {
  color: rgb(255, 255, 255);
  font-size: 14px;
}
.footer_top .container {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}
.footer_top .container>div {
  display: flex;
  margin: 20px 0;
  max-width: 300px;
  align-items: flex-start;
}
.footer_main .container {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.footer_main div, .footer_main a, .footer_main li {
  color: rgba(255, 255, 255, 0.7);
}
.footer_main ul li+li {
  margin-top: 5px;
}
.footer_main a:hover {
  color: #fff;
}
.footer_main .title {
  color: #fff;
  font-size: 16px;
}
.footer_terms p {
  color: rgba(255,255,255,0.7);
}
.footer_copyright {
  background-color: #24262b;
}
.footer_copyright .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer_copyright .payments img {
  max-width: 250px;
}
.side-blocks {
  position: fixed;
  top: 18%;
  right: 0;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  width: 200px;
  justify-content: flex-end;
}
.floating-whatsapp {
  position: fixed;
  bottom: 50px;
  right: 20px;
  background-color: #25d366;
  width: 60px;
  height: 60px;
  z-index: 100;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: center;
}
footer ul {
  list-style: none;
  padding: 0;
}
.footer_main div, .footer_main a, .footer_main li {
  color: rgba(255, 255, 255, 0.7);
}
.button {
  background: #01d8da;
  color: #fff;
  cursor: pointer;
  border: none;
  font-size: 15px;
  line-height: 20px;
  padding: 5px 10px;
  transition: all 200ms ease 0s;
  border-width: 1px;
  border-style: solid;
  border-color: #01d8da;
  text-align: center;
  border-radius: 4px;
  outline: none;
  display: inline-block;
  
}
 a.button {
  padding: 3px 30px;
  font-size: 18px;
  border-radius: 50px;
}
.pricepromise-3 h2 ,.pricepromise-2 h2,.pricepromise-1 h2{
  border-bottom: 10px solid #01d8da;
  display: inline-block;
  font-size: 28px;
  line-height: 1.8;
}
.pricepromise-3 p,.pricepromise-2 p,.pricepromise-2 p {
  font-size: 18px;
  margin: 20px auto;
  line-height: 2;
}
a{
  text-decoration: none;
}
.header-icons {
  background-color: #f7ffff;
  margin-top: 70px;
}
.header-icons ul {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1380px;
}
.header-icons ul li {
  width: 100%;
}

.header-icons ul li a {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 10px 0;
}
.header-icons ul li img {
  width: 30px;
  margin-right: 10px;
}
.main-content{
  margin-top: 160px;
}
.product-list>.product-grid {
  width: 20%;
  margin-bottom: 20px;
  height: inherit;
  position: relative;
}
.product-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  min-width: 16.6666%;
  padding: 10px;
  height: 100%;
  position: relative;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.product-grid a.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: -10px;
  z-index: 1;
}
.product-grid>div {
  width: 100%;
}
.product-grid .image {
  text-align: center;
  position: relative;
  width: 100%;
  align-self: flex-start;
  background-color: rgb(250, 250, 250);
  border-radius: 5px;
}
.product-grid .image>a {
  margin-bottom: 10px;
  padding: 10px;
  display: block;
}
.product-grid .image img {
  mix-blend-mode: multiply;
  border-radius: 5px;
  width: 100%;
  max-width: 150px;
}
.product-grid .product_grid_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.catproduct{
  margin-left: 230px;
}
.product-grid .name {
  margin: 0 0 5px;
  line-height: 1.2;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.product-grid .product_number_sold {
  font-size: 12px;
  margin-bottom: 5px;
}
.product-grid .button {
  display: block;
  align-self: flex-end;
  z-index: 2;
  position: relative;
}
.product-grid .price {
  display: flex;
  align-items: center;
  color: #e91e63;
}
.product-grid .price-new {
  font-size: 18px;
  color: #e91e63;
  font-weight: bold;
}
.mini-cart-info {
  max-height: 300px;
  overflow: auto;
  font-size: 13px;
  line-height: 1.2;
  width: 450px;
}
.mini-cart-info .cart-product-list {
  width: 100%;
}
.mini-cart-info td {
  vertical-align: top;
  padding: 5px;
}
.mini-cart-info .image img {
  width: 40px;
  height: 40px;
}
.mini-cart-info td.quantity {
  display: flex;
  align-items: center;
}
.quantity_wrap {
  display: flex;
}
.stepper.down {
  border-radius: 4px 0 0 4px;
}
.quantity_wrap .stepper {
  border-radius: 2px;
  background: #f5f5f5;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.stepper.down+input {
  border-radius: 0;
  background: #fff;
  border: 1px solid #f5f5f5;
  text-align: center;
  padding: 5px 5px;
}
.quantity_wrap input {
  width: 40px;
}
.mini-cart-info {
  max-height: 300px;
  overflow: auto;
  font-size: 13px;
  line-height: 1.2;
  width: 450px;
}
 #cart:hover #cart-content {
  display: block;
  min-width: 250px;
}
#cart{
  position: relative;
}
 #cart-content  {
  display: none;
  position: absolute;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  right: 0;
}
.mini-cart-info td.quantity .btn_remove {
  margin-left: 10px;
}
#cart .btn_remove {
  background-image: url(https://bip-react.trillionhk.com/image/removeicon.png);
  background-size: 100%;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}
#notification>div {
  position: fixed;
  width: 300px;
  z-index: 9999;
  background: #fff;
  margin: 0 auto;
  top: calc(50% - 100px);
  left: calc(50% - 150px);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border: 3px solid #01d8da;
}
.mini-cart-info td a{
  box-sizing: border-box;
    text-decoration: none;
    color: rgba(0,0,0,0.85);
}
.mini-cart-total {
  text-align: right;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.mini-cart-total td {
  padding: 0 10px;
}
#cart .checkout {
  padding: 10px;
}
#cart .checkout .button {
  display: block;
  width: 350px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
